<template>
	<div>
		<el-button class="buttons" type="primary" icon="el-icon-plus" @click="addUsers">添加人员</el-button>
		<el-table border ref="multipleTable" :data="listdatas" tooltip-effect="dark" style="width: 100%">
			<el-table-column prop="userName" label="姓名">
			</el-table-column>
			<el-table-column prop="userName" label="职位类别">
				<template slot-scope="scope">
					{{scope.row.roleId == 2 ?'职员':scope.row.roleId == 3 ? '主管' : scope.row.roleId == 4 ? '设备管理员' : '无'}}
				</template>
			</el-table-column>
			<el-table-column prop="userTel" label="手机号">
			</el-table-column>
			<el-table-column prop="userPassword" label="账号密码">
			</el-table-column>
			<el-table-column width="80" label="操作">
				<template slot-scope="scope">
					<!-- <el-tooltip content="查看" placement="bottom" effect="light">
						<el-button size="mini" type="primary" icon="el-icon-search">
						</el-button>
					</el-tooltip> -->
					<el-tooltip content="修改" placement="bottom" effect="light">
						<el-button @click="info(scope.row)" size="mini" type="primary" icon="el-icon-edit">
						</el-button>
					</el-tooltip>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog :show-close="false" :title="this.ruleForm.id == 0 ?'添加':'修改'" :visible.sync="dialogVisible"
			:close-on-click-modal="false">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="姓名" prop="userName">
					<el-input v-model="ruleForm.userName" :disabled="userNameg"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="userTel">
					<el-input v-model="ruleForm.userTel"></el-input>
				</el-form-item>
				<el-form-item label="人员类别" prop="roleId">
					<!-- <el-select v-model="ruleForm.roleId" placeholder="请选择人员类别">
						<el-option label="部门主管" :value="3"></el-option>
						<el-option label="普通职员" :value="2"></el-option>
						<el-option label="设备管理员" :value="4"></el-option>
					</el-select> -->
					<el-select :disabled="userNameg" v-model="ruleForm.roleId" placeholder="请选择人员类别">
						<el-option label="部门主管" :value="3"></el-option>
						<el-option label="普通职员" :value="2"></el-option>
						<el-option label="设备管理员" :value="4"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="密码" prop="userPassword">
					<el-input v-model="ruleForm.userPassword" show-password></el-input>
				</el-form-item>
				<el-form-item label="帐号状态" >
					<el-switch @change="switchchange"
						v-model="ruleForm.userFiled3" active-text="启用" inactive-text="禁用">
					</el-switch>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="resetForm">取 消</el-button>
				<el-button type="primary" @click="submitForm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				listdatas: [],
				dialogVisible: false,
				userNameg: false,
				userId: sessionStorage.getItem('userId'),
				roleId: sessionStorage.getItem('roleId'),
				ruleForm: {
					userName: '',
					userPassword: '',
					userTel: "",
					roleId: "",
					userFiled3:true
				},
				rules: {
					userName: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					userPassword: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}],
					roleId: [{
						required: true,
						message: '请选择人员类别',
						trigger: 'change'
					}],
					userTel: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}, {
						pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
						message: "请输入合法手机号/电话号",
						trigger: "blur"
					}]
				}
			}
		},
		created() {
			this.getuserList()
		},
		mounted() {

		},
		methods: {
			async getuserList() {
				this.listdatas = []
				await this.$post('login/selectUserList').then((res) => {
					if (res && res.length > 0) {
						if (this.roleId == 1) {
							res.forEach((e, m) => {
								if (e.roleId != 1) {
									this.listdatas.push(e)
								}
							})
						}
					}
				})
				this.$set(this.listdatas)
			},
			info(data) {
				this.ruleForm.userName = data.userName
				this.ruleForm.userPassword = data.userPassword
				this.ruleForm.userTel = data.userTel
				this.ruleForm.id = data.id
				this.ruleForm.roleId = data.roleId
				this.ruleForm.userFiled3 = data.userFiled3 == 0 ? true : false
				this.dialogVisible = true
				this.userNameg = true
			},
			addUsers() {
				this.ruleForm.userName = ''
				this.ruleForm.userPassword = ''
				this.ruleForm.userTel = ''
				this.ruleForm.id = 0
				this.ruleForm.roleId = 2
				this.dialogVisible = true
				this.userNameg = false
				this.ruleForm.userFiled3 = true
			},
			submitForm(formName) {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.ruleForm.userFiled3 ? this.ruleForm.userFiled3 = 0 : this.ruleForm.userFiled3 = 1
						console.log(this.ruleForm)
						this.$post('login/updateUser', this.ruleForm).then((res) => {
							this.$message({
								message: this.ruleForm.id == 0 ? '添加成功' : '修改成功',
								type: 'success'
							});
							this.getuserList()
							this.dialogVisible = false
						})
					} else {
						this.$message({
							message: '信息不完整',
							type: 'warning'
						});
					}
				});
			},
			resetForm(formName) {
				this.$refs['ruleForm'].resetFields();
				this.dialogVisible = false
			},
			switchchange(val){
				console.log(val)
			}
		}
	}
</script>

<style scoped lang="less">
	.buttons {
		margin-bottom: 40px;
	}
</style>
